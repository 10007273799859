import MessageBanner from '@/components/Banner/MessageBanner';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { Box, Text, theme } from '@fivehealth/botero';
import moment from 'moment';

export const TemporaryDowtimeBanner: React.FC<{ isHideable?: boolean }> = ({ isHideable }) => {
  const currDate = moment(moment().format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH_TIME_1));
  const endDate = moment('2025-01-24T12:00:00+08:00'); // Jan. 24, 2025 12.00pm SGT

  const isHideBanner = currDate.isAfter(endDate);

  return !isHideBanner ? (
    <MessageBanner
      open
      sticky
      type="error"
      hideClose={!isHideable}
      content={
        <Box>
          <Text as="span" color={theme.colors.danger}>
            Due to Parkway Radiology system maintenance, ordering tests for new patients 
            will be unavailable from 1am to 4am SGT on Friday, January 24th. 
            We apologize for any inconvenience.
          </Text>
        </Box>
      }
      bannerProps={{ mb: 2, mt: -2 }}
    />
  ) : null;
};
